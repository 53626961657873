// Routes.js
import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppContext from '../context/AppContex';
import Home from '../containers/Home';
import Setting from '../containers/Setting';
import Log from '../containers/Log';
import Order from '../containers/Order';
import OrderDetail from '../containers/OrderDetail';
import NotFound from '../containers/NotFound';
import Commune from '../containers/Commune';
import Test from '../containers/Test';

function Routes() {
  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/" component={Order} />
      <Route exact path="/orderdetail/:id" component={OrderDetail} />
      <Route exact path="/setting/configure" component={Setting} />
      <Route exact path="/setting/log" component={Log} />
      <Route exact path="/setting/commune" component={Commune} />
      <Route exact path="/contacto" component={Test} />
      <PrivateRoute exact path="/checkout-ad">
        <Home />
      </PrivateRoute>
      <Route
        path="/account"
        render={({ match: { url } }) => (
          <>
            <Route exact path={`${url}/affiliation`} component={Home} />
          </>
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { isAuth } = useContext(AppContext);
  const auth = { user: isAuth };
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
}

export default Routes;
