import React, { useState, useCallback, useEffect } from 'react';
import {
  FormLayout,
  TextField,
  Button,
  SettingToggle,
  TextStyle,
  Page,
  Banner,
  RadioButton,
  Card,
} from '@shopify/polaris';
import Loading from '../components/Loading';

const Setting = () => {
  const shop = 'swa-pruebas.myshopify.com';
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [dateSelect, setdateSelect] = useState();
  const [dateForm, setDateForm] = useState();
  const [user, setUser] = useState('');

  const [rut, setRut] = useState('');
  const [courierText, setCourierText] = useState('');
  const [preparationDays, setPreparationdays] = useState('');
  const [token, setToken] = useState('false');
  const [courier, setCourier] = useState('si');
  const [dates, setDates] = useState([]);

  const handleChange = useCallback(
    (_checked, newValue) => setCourier(newValue),
    []
  );
  const handleUser = useCallback((newValue) => setUser(newValue), []);
  const handleToken = useCallback((newValue) => setToken(newValue), []);
  const handleRut = useCallback((newValue) => setRut(newValue), []);
  const handleDays = useCallback(
    (newValue) => setPreparationdays(newValue),
    []
  );
  const handleCourierText = useCallback(
    (newValue) => setCourierText(newValue),
    []
  );

  const contentStatus = !active ? 'Activar' : 'Desactivar';
  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting?shop=${shop}`, {
        method: 'GET',
      })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.url) {
          setUser(resp.url);
        }
        if (resp.token) {
          setToken(resp.token);
        }
        if (resp.automatic) {
          console.log(`automatic is: ${resp.automatic}`);
          setActive(resp.automatic === '1');
        }
        if (resp.courier) {
          setCourier(resp.courier);
        }
        if (resp.preparationdays) {
          setPreparationdays(resp.preparationdays);
        }
        if (resp.rut) {
          setRut(resp.rut);
        }
        if (resp.courierText) {
          setCourierText(resp.courierText);
        }
        if (resp.holidays) {
          setDates(JSON.parse(resp.holidays));
        }
        console.log(resp);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  const submitForm = () => {
    const data = {
      url: user,
      token,
      automatic: active,
      shop,
      rut,
      preparationdays: preparationDays,
      courier,
      courierText,
      holidays: JSON.stringify(dates),
    };

    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting?shop=${shop}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        method: 'POST',
      })
      .then((response) => response.json())
      .then((resp) => {
        console.log(resp);
        setIsMessage(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  };

  const updateActive = (status) => {
    const data = {
      automatic: status ? 1 : 0,
      shop,
    };

    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting?shop=${shop}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        method: 'POST',
      })
      .then((response) => response.json())
      .then((resp) => {
        console.log(resp);
        setIsMessage(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  };
  const handleToggle = () => {
    if (active) {
      updateActive(0);
      setActive(false);
    } else {
      updateActive(1);
      setActive(true);
    }
  };

  const handleDate = (e) => {
    setdateSelect(e.target.value);
  };

  const addDays = () => {
    setDates([...dates, dateSelect]);
  };

  const removeDays = () => {
    const temp = [...dates];
    temp.splice(dateForm, 1);
    setDates(temp);
  };

  const showFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const provText = text.split(';');
      const provSig = [...dates];
      Array.prototype.push.apply(provSig, provText);
      setDates(provSig);
    };
    reader.readAsText(e.target.files[0]);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Page title="Configuración">
            {isMessage && (
              <Banner
                title="Modificación Realizada"
                status="success"
                onDismiss={() => {
                  setIsMessage(false);
                }}
              />
            )}
            <FormLayout>
              <TextField label="Nombre de Tienda" value={shop} disabled />
              <TextField
                label="Correo Electronico Sendu"
                value={user}
                onChange={handleUser}
                error={
                  user.length < 1 ? 'Correo Electronico es Requerido' : null
                }
              />
              <TextField
                label="Token Sendu"
                value={token}
                onChange={handleToken}
                error={token.length < 1 ? 'Token es Requerido' : null}
              />
              <TextField
                label="RUT Tienda"
                value={rut}
                onChange={handleRut}
                error={rut.length < 1 ? 'RUT Tienda es Requerido' : null}
              />
              <p>¿Mostrar nombre de Courier?</p>
              <RadioButton
                label="Si"
                id="si"
                name="courier"
                checked={courier === 'si'}
                onChange={handleChange}
              />
              <RadioButton
                label="No"
                id="no"
                name="courier"
                checked={courier === 'no'}
                onChange={handleChange}
              />
              {courier === 'no' && (
                <TextField
                  value={courierText}
                  onChange={handleCourierText}
                  helpText={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <span>
                      (Opcional) texto a mostrar si no se muestra el courier
                    </span>
                  }
                />
              )}
              <TextField
                label="Días de preparación del pedido"
                value={preparationDays}
                onChange={handleDays}
                helpText={
                  <span>(Opcional) Dias a sumar a la fecha de entrega</span>
                }
              />
              <SettingToggle
                action={{
                  content: contentStatus,
                  onAction: handleToggle,
                }}
                enabled={active}
              >
                Emisión y Orden de Flete:
                <TextStyle variation="strong">
                  {' '}
                  {active ? 'Activo' : 'Desactivado'}
                </TextStyle>
                .
                <br />
                Emite de forma automatica al finalizar la compra las ordenes de
                flete.
              </SettingToggle>

              <Card title="Dias festivos fecha(dd/mm/aaaa)" sectioned>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ flex: '50%' }}>
                    Estas fechas de dias festivos no se tomaran
                    <br />
                    en cuenta para el calculo de la fecha de entrega
                    <br />
                    <br />
                    <input
                      type="date"
                      name="expiration date"
                      value={dateSelect}
                      onChange={handleDate}
                    />
                    <button type="button" onClick={() => addDays()}>
                      Agregar Fecha
                    </button>
                  </div>
                  <div style={{ flex: '50%' }}>
                    Se debe subir un archivo .dat (sin formato) con las fechas,
                    separadas por punto y coma (;) - ejemplo:
                    aaaa-mm-dd;aaaa-mm-dd
                    <br />
                    <br />
                    <input
                      type="file"
                      onChange={showFile}
                      accept=".dat, .date"
                    />
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <label>Dias Seleccionados</label>
                  <select
                    multiple="multiple"
                    className="multiselect "
                    style={{ display: 'block', height: '150px' }}
                    onChange={(e) => setDateForm(e.target.value)}
                  >
                    {dates.map((item, key) => (
                      <option value={key}>{item}</option>
                    ))}
                  </select>

                  <button type="button" onClick={() => removeDays()}>
                    Remover Fecha
                  </button>
                </div>
              </Card>
              <Button
                outline
                onClick={() => submitForm()}
                disabled={token.length < 1 || user.length < 1}
              >
                Guardar Configuración
              </Button>
            </FormLayout>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Page>
        </>
      )}
    </>
  );
};
export default Setting;
