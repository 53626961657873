import { useState } from "react";
import initialState from '../initialState'

const useInitialState = () => {
    const [state, setState] = useState(initialState);

    const [isAuth, setIsAuth] = useState(() => {
        return JSON.parse(window.sessionStorage.getItem('token'))
    })


    const activateAuth = (token) => {
        window.sessionStorage.setItem('token', JSON.stringify(token))
        setIsAuth(token);
    }

    const removeAuth = () =>{
        window.sessionStorage.removeItem('token')
        setIsAuth('')
    }


    const addToCart = payload => {
        setState({
            ...state,
            cart: [...state.cart, payload]
        })
    }

    const removeFromCart = payload =>{
        setState({
            ...state,
            cart: state.cart.filter(items => items.id !== payload.id)
        })
    }


    const addLetter = payload => {
        setState({
            ...state,
            letter: payload
        })
    }

    const addNumber = payload => {
        setState({
            ...state,
            numberPhone: payload
        })
    }
    const addPaymentPreview = payload => {
        setState({
            ...state,
            paymentPreview: payload
        })
    }
    const addPayment = payload => {
        setState({
            ...state,
            payment: payload
        })
    }
    const addUser = payload => {
        setState({
            ...state,
            userInfo: payload
        })
    }
    const addReponseIzi = payload => {
        setState({
            ...state,
            iziResponse: payload
        })
    }
    const addUbication = payload => {
        window.sessionStorage.setItem('ubications', JSON.stringify(payload))
        setState({
            ...state,
            ubications: payload
        })
    }

    
    return {
        activateAuth,
        removeAuth,
        addToCart,
        removeFromCart,
        addLetter,
        addNumber,
        addPayment,
        addUser,
        addReponseIzi,
        addPaymentPreview,
        addUbication,
        isAuth,
        state,
    };
};



export default useInitialState;

