export default {
  cart: [],
  buyer:[],
  letter:[],
  numberPhone:"",
  payment:[],
  ubications:[],
  paymentPreview:[],
  userInfo:[],
  iziResponse:[]
};