import React from 'react';


const Test = () => {
  return (
    <div>
      <h1>PAGINA 2</h1>
    </div>
  );
}

export default Test;