import React from 'react';
import { Card, EmptyState, Page } from '@shopify/polaris';
import Image from '../assets/imagen.png';

const Home = () => {
  return (
    <>
      <Page>
        <Card sectioned>
          <EmptyState
            heading="Sendu Multicourier"
            secondaryAction={{
              content: 'Conoce Sendu',
              url: 'https://www.sendu.cl',
              external: true,
            }}
            footerContent={<p>La manera más simple de enviar </p>}
            image={Image}
          >
            <p>Información clave para tu día a día</p>
          </EmptyState>
        </Card>
      </Page>
    </>
  );
};

export default Home;
