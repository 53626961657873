import React, { useState, useRef, useCallback } from 'react';
import {
  Frame,
  ActionList,
  Navigation,
  TopBar,
  Loading,
} from '@shopify/polaris';
import { HomeMajor, OrdersMajor, SettingsMajor } from '@shopify/polaris-icons';
import { useLocation } from 'react-router-dom';
import Routes from '../routes/Routes';
import Sendu from '../assets/logo/logo.png';

const logo = {
  width: 124,
  topBarSource: Sendu,
  contextualSaveBarSource: Sendu,
  url: '#',
  accessibilityLabel: 'Sendu Multicourier - La manera más simple de enviar',
};

const Layout = ({ children }) => {
  const location = useLocation();

  const defaultState = useRef({
    emailFieldValue: 'dharma@jadedpixel.com',
    nameFieldValue: 'Jaded Pixel',
  });
  const skipToContentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [storeName] = useState(defaultState.current.nameFieldValue);

  const toggleIsLoading = useCallback(
    // eslint-disable-next-line no-shadow
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleUserMenuActive = useCallback(
    // eslint-disable-next-line no-shadow
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        // eslint-disable-next-line no-shadow
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
  }, []);

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: 'Shopify help center' },
        { content: 'Community forums' },
      ]}
    />
  );

  const loadingMarkup = isLoading ? <Loading /> : null;

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      searchResultsVisible={searchActive}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location={location.pathname}>
      <Navigation.Section
        separator
        title="Sendu Multicourier"
        items={[
          {
            url: '/home',
            label: 'Inicio',
            icon: HomeMajor,
            onClick: toggleIsLoading,
          },

          {
            url: '/',
            label: 'Ordenes',
            icon: OrdersMajor,
            onClick: toggleIsLoading,
          },
          {
            url: '/setting/configure',
            label: 'Configuración',
            icon: SettingsMajor,
            onClick: toggleIsLoading,
            subNavigationItems: [
              {
                url: '/setting/configure',
                label: 'Configuración de Sistema',
              },
              {
                url: '/setting/log',
                label: 'Sistema Log',
              },
            ],
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame
      logo={logo}
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef.current}
    >
      {loadingMarkup}
      {children}
      <Routes />
    </Frame>
  );
};

export default Layout;
