import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormLayout,
  TextField,
  Button,
  Loading,
  Card,
  Page,
} from '@shopify/polaris';

const OrderDetail = () => {
  const { id } = useParams();
  const shop = 'swa-pruebas.myshopify.com';
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [order, setOrder] = useState([]);
  const [shopify, setShopify] = useState([]);
  const [tracking, setTracking] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/order-detail/${id}?shop=${shop}`,
        {
          method: 'GET',
        }
      )
      .then((response) => response.json())
      .then((resp) => {
        console.log(resp);
        setOrder(resp.order);
        setShopify(resp.shopify);
        setTracking(resp.tracking);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Page
          narrowWidth
          breadcrumbs={[{ content: 'Ordenes', url: '/' }]}
          title="Detalle de Orden"
        >
          <Card title="Información de Envio" sectioned>
            <p>
              Número de Envio Sendu - #SP
              {order?.number_order}
            </p>
          </Card>
          <Card.Section title="Nombre de Cliente">
            <p>{shopify?.shipping_address?.name} </p>
          </Card.Section>
          <Card.Section title="Telefono de Cliente">
            <p>{shopify?.shipping_address?.phone} </p>
          </Card.Section>
          <Card.Section title="Email de Cliente">
            <p>{tracking?.email} </p>
          </Card.Section>
          <Card.Section title="Entrega">
            <p>{order?.forma_entrega} </p>
          </Card.Section>
          <Card.Section title="Información de Envio">
            <p>
              Peso:
              {tracking?.weight}
            </p>
            <p>
              Altura:
              {tracking?.height}
            </p>
            <p>
              Grande:
              {tracking?.large}
            </p>
            <p>
              Profundo:
              {tracking?.deep}
            </p>
          </Card.Section>
          <Card.Section title="Fecha estimada de entrega">
            <p>{order?.transit_days}</p>
          </Card.Section>
        </Page>
      )}
    </>
  );
};

export default OrderDetail;
