import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '@shopify/polaris/build/esm/styles.css';
import esTranslations from '@shopify/polaris/locales/es.json';
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import Layout from '../components/Layout';
import AppContext from '../context/AppContex';
import useInitialState from '../hooks/useInitialState';

function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  // console.log(query)//"app=article&act=news_content&aid=160990"
  const vars = query.split('&');
  // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}
const App = () => {
  const initialState = useInitialState();
  const [loading] = useState(0);
  const shopOrigin = useMemo(() => {
    if (!getQueryVariable('host')) {
      return localStorage.getItem('host');
    }
    localStorage.setItem('host', getQueryVariable('host'));
    localStorage.setItem('shop', getQueryVariable('shop'));
    return getQueryVariable('host');
  }, [loading]);
  console.log(shopOrigin);
  const config = {
    apiKey: '2bc167fdc4204a60e277c5dca01b54c1',
    host: shopOrigin,
    //host: 'c3dhLXBydWViYXMubXlzaG9waWZ5LmNvbS9hZG1pbg',
    forceRedirect: true,
  };

  if (window.performance.navigation.type === 1) {
    window.location.href = '/';
  }
  return (
    <AppProvider i18n={esTranslations}>
      <AppContext.Provider value={initialState}>
        <Provider config={config}>
          <Router>
            <Layout />
          </Router>
        </Provider>
      </AppContext.Provider>
    </AppProvider>
  );
};
export default App;
