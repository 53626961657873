import React, { useState, useEffect } from 'react';
import '../styles/components/Setting.css';
import { Card, Page } from '@shopify/polaris';

const Log = () => {
  const shop = 'swa-pruebas.myshopify.com';
  const [isLoading, setIsLoading] = useState(false);
  const [listProjects, setlistProjects] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    window
      .fetch(`${process.env.REACT_APP_BASEURL}/setting-log?shop=${shop}`, {
        method: 'GET',
      })
      .then((response) => response.json())
      .then((resp) => {
        console.log(resp);
        let valores = resp.map((currElement) => {
          const response = JSON.parse(currElement.response);
          const data = {
            title: currElement.title,
            created: currElement.created,
            response: JSON.stringify(response),
          };
          return data;
        });
        console.log(valores);
        setlistProjects(valores);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  }, []);

  return (
    <Page title="Log de Eventos">
      <Card>
        {listProjects.map((item, index) => (
          <Card.Section title={`${item.title} - ${item.created}`}>
            <p>{item.response}</p>
          </Card.Section>
        ))}
      </Card>
    </Page>
  );
};

export default Log;
